import * as ACTION from './actionTypes'
import {
	getAllSubscriptionsApi,
	sendCardTokenApi,
	getActivePlanDetailsApi,
	getPaymentTransactionsApi,
	getGigapixelTransactionsApi,
	cancelPlanApi,
	validateDiscountCodeApi,
	confirmPaymentApi,
	cancelPaymentApi,
} from './api'

export const getAllSubscriptions = () => {
	return {
		type: ACTION.GET_ALL_SUBSCRIPTIONS,
		payload: getAllSubscriptionsApi(),
	}
}

export const sendCardToken = body => {
	return {
		type: ACTION.SEND_CARD_TOKEN,
		payload: sendCardTokenApi(body),
	}
}

export const getActivePlanDetails = queryParam => {
	return {
		type: ACTION.GET_ACTIVE_PLAN_DETAILS,
		payload: getActivePlanDetailsApi(queryParam),
	}
}

export const getPaymentTransactions = queryParam => {
	return {
		type: ACTION.GET_PAYMENT_TRANSACTIONS,
		payload: getPaymentTransactionsApi(queryParam),
	}
}

export const getGigapixelTransactions = queryParam => {
	return {
		type: ACTION.GET_GIGAPIXEL_TRANSACTIONS,
		payload: getGigapixelTransactionsApi(queryParam),
	}
}

export const cancelPlan = body => {
	return {
		type: ACTION.CANCEL_PLAN,
		payload: cancelPlanApi(body),
	}
}

export const validateDiscountCode = queryParam => {
	return {
		type: ACTION.VALIDATE_DISCOUNT_CODE,
		payload: validateDiscountCodeApi(queryParam),
	}
}

export const confirmPayment = () => {
	return {
		type: ACTION.CONFIRM_PAYMENT,
		payload: confirmPaymentApi(),
	}
}

export const cancelPayment = () => {
	return {
		type: ACTION.CANCEL_PAYMENT,
		payload: cancelPaymentApi(),
	}
}
