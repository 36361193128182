import React, { useEffect, useRef, useState } from 'react'
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements'
import { message, Spin } from 'antd'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { sendCardToken, confirmPayment, cancelPayment, getActivePlanDetails } from '../../Stores/Subscription/action'
import styles from './payments.module.scss'
import AeroButton from '../Button'
import { IMAGES_SRC } from '../Image/image.constants'
import AeroImage from '../Image'
import AeroModal from '../Modal'
import GeneralConfirmationContent from '../Modal/general-confirmation-content'

const CardSetupForm = ({
	stripe,
	elements,
	planId,
	sendCardToken,
	discountCode,
	confirmPayment,
	paymentConfirmed,
	handlePostSubscription,
	cancelPayment,
	paymentCancelled,
	handlePaymentCancelled,
	getActivePlanDetails,
	activePlanData,
	callSubscriptionBoughtMixpanelEvent,
	setOldPlan,
	currentPlan,
	discountPercentage,
	payBtnText,
	freeTrialAvailed,
}) => {
	const [loader, setLoader] = useState(false)
	const [paymentLoader, setPaymentLoader] = useState(false)
	const [name, setName] = useState(null)
	const [waitForApi, setWaitForApi] = useState(false)
	const [paymentIntentModal, setPaymentIntentModal] = useState(false)
	const [fetchedPlan, setFetchedPlan] = useState(null)
	const [btnLoader, setBtnLoader] = useState(false)
	const [btnText, setBtnText] = useState('Yes, go ahead')
	const cardNumberRef = useRef()
	const cardExpiryRef = useRef()
	const cardCvcRef = useRef()

	const createOptions = (fontSize, padding) => {
		return {
			style: {
				base: {
					fontSize,
					color: '#424770',
					letterSpacing: '0.025em',
					fontFamily: 'Source Code Pro, monospace',
					'::placeholder': {
						color: '#aab7c4',
					},
					padding,
				},
				invalid: {
					color: '#9e2146',
				},
			},
		}
	}

	const handleSubmit = async () => {
		setBtnLoader(true)
		try {
			if (stripe && elements) {
				const stripeToken = await stripe.createToken({ type: 'card', name })
				setLoader(true)
				let payload = {
					cardToken: stripeToken.token.id,
					subscriptionPlanId: planId,
					couponCode: discountPercentage && discountCode ? discountCode : null,
				}
				setWaitForApi(true)
				sendCardToken(payload)
					.then(res => {
						if (!freeTrialAvailed && res.value.data.clientSecret) {
							stripe.confirmCardSetup(res.value.data.clientSecret).then(result => {
								if (result.error) {
									message.error(result.error.message)
									setBtnText('Retry')
								} else {
									setPaymentLoader(false)
									handlePostPayment(res.value.data)
								}
							})
						} else {
							callSubscriptionBoughtMixpanelEvent()
							setTimeout(() => {
								setLoader(false)
								handlePostPayment(res.value.data)
							}, 2000)
						}
					})
					.catch(() => {
						setLoader(false)
					})
					.finally(() => {
						setWaitForApi(false)
						setBtnLoader(false)
					})
			} else {
				message.error("Stripe.js hasn't loaded yet. Please retry")
			}
		} catch (error) {
			setWaitForApi(false)
			setLoader(false)
			setBtnLoader(false)
		}
	}

	useEffect(() => {
		if (paymentCancelled && waitForApi) {
			setWaitForApi(false)
			setPaymentLoader(false)
			setPaymentIntentModal(false)
			handlePaymentCancelled()
		}
	}, [paymentCancelled])

	useEffect(() => {
		if (paymentConfirmed && waitForApi) {
			setPaymentLoader(true)
			setWaitForApi(false)
			if (paymentConfirmed.active) {
				setPaymentLoader(false)
				setPaymentIntentModal(false)
				handlePostSubscription(fetchedPlan)
			} else {
				stripe.confirmCardPayment(paymentConfirmed.clientSecret).then(result => {
					if (result.error) {
						message.error(result.error.message)
						setPaymentLoader(false)
						setBtnText('Retry')
					} else {
						setPaymentLoader(false)
						setPaymentIntentModal(false)
						handlePostSubscription(fetchedPlan)
					}
				})
			}
		}
	}, [paymentConfirmed])

	useEffect(() => {
		if (activePlanData && waitForApi) {
			setWaitForApi(false)
			setBtnText('Yes, go ahead')
			if (activePlanData.status === 'ACTIVE' || activePlanData.status === 'PENDING' || activePlanData.status === 'UPCOMING') {
				handlePostSubscription(fetchedPlan)
			} else {
				setPaymentIntentModal(true)
			}
		}
	}, [activePlanData])

	const handlePostPayment = plan => {
		setFetchedPlan(plan)
		setWaitForApi(true)
		setOldPlan(currentPlan)
		getActivePlanDetails({ plan_id: plan.id })
	}

	const paymentIntentConfirmation = () => {
		setWaitForApi(true)
		confirmPayment()
	}

	const handleIntentCancel = () => {
		setWaitForApi(true)
		cancelPayment()
	}

	return (
		<>
			<Spin spinning={loader}>
				<>
					<label className={styles['label']}>
						Card Number
						<CardNumberElement ref={cardNumberRef} {...createOptions(20)} />
					</label>
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<label style={{ width: '45%' }} className={styles['label']}>
							Expiration Date
							<CardExpiryElement ref={cardExpiryRef} {...createOptions(20)} />
						</label>
						<label style={{ width: '45%' }} className={styles['label']}>
							CVC
							<CardCVCElement ref={cardCvcRef} {...createOptions(20)} />
						</label>
					</div>

					<label className={styles['label']}>
						Card Holder's Name
						<input className={styles['name']} placeholder="Card holder's Name" type='text' value={name} onChange={e => setName(e.target.value)} />
					</label>
					<div style={{ color: '#8C8C8C', textAlign: 'center', margin: '30px', fontWeight: '600' }}>
						PAYMENTS POWERED BY <AeroImage alt='stripe' otherstyles={{ padding: '5px', marginTop: '-3px' }} src={IMAGES_SRC.STRIPE_LOGO} />
					</div>
					<div className={styles['submit_button']}>
						<AeroButton loading={btnLoader} type='primary' block onClick={handleSubmit}>
							{payBtnText}
						</AeroButton>
					</div>
				</>
			</Spin>
			<AeroModal footer={false} visible={paymentIntentModal} onCancel={handleIntentCancel}>
				<Spin spinning={paymentLoader}>
					<GeneralConfirmationContent
						primaryBtnAction={paymentIntentConfirmation}
						primaryBtnText={btnText}
						secondaryBtnAction={handleIntentCancel}
						secondaryBtnText='No, cancel payment'
						topAsset={IMAGES_SRC.BIG_CHECK_ICON}
						heading='Do you confirm the payment?'
						description='Please note that if you cancel your subscription, you will lose your gigapixel balance and will not be able to process any images after the plan ends.'
					/>
				</Spin>
			</AeroModal>
		</>
	)
}

const mapStateToProps = state => {
	return {
		paymentConfirmed: state.SubscriptionReducer.paymentConfirmed,
		paymentCancelled: state.SubscriptionReducer.paymentCancelled,
		activePlanData: state.SubscriptionReducer.activePlanData,
	}
}

const mapDispatchToProps = dispatch => {
	return {
		sendCardToken: bindActionCreators(sendCardToken, dispatch),
		confirmPayment: bindActionCreators(confirmPayment, dispatch),
		cancelPayment: bindActionCreators(cancelPayment, dispatch),
		getActivePlanDetails: bindActionCreators(getActivePlanDetails, dispatch),
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(injectStripe(CardSetupForm))
