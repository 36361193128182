import { message } from 'antd'
import uuid from 'uuid-random'
import { getValueFromStorage } from '../Services/LocalStorageService'
import { AEROLOGIX_ENDPOINTS } from './api-endpoints'
import { logOut, toggleAppLoading } from '../Stores/UserSession/action'
import AppStore from '../store'
import { bindActionCreators } from 'redux'
import { goTo } from '../Services/NavigationService'
import {
	CONTACT_SUPPORT,
	GET_COUNTRY_LIST,
	GET_SOCIAL_AUTH,
	LOGIN,
	RECOVER_PASSWORD,
	REGISTER_USER,
	REPORT_ERROR,
	UPDATE_PROFILE,
	GET_OTP,
	RESEND_VERIFICATION_LINK,
} from '../Stores/UserSession/actionTypes'
import { CANCEL_PAYMENT, CANCEL_PLAN, CONFIRM_PAYMENT, SEND_CARD_TOKEN, VALIDATE_DISCOUNT_CODE } from '../Stores/Subscription/actionTypes'
import { CHARGE_USER, CREATE_PROCESSING, DELETE_PROCESSING, SHARE_PROCESSING } from '../Stores/MyMaps/actionTypes'

const store = AppStore
let isUploadUrl = false
// const PAGE_LOADER_API = [];
const MODAL_LOADER_API = [
	CONTACT_SUPPORT,
	UPDATE_PROFILE,
	SEND_CARD_TOKEN,
	CANCEL_PLAN,
	CREATE_PROCESSING,
	SHARE_PROCESSING,
	DELETE_PROCESSING,
	VALIDATE_DISCOUNT_CODE,
	CHARGE_USER,
	CONFIRM_PAYMENT,
	CANCEL_PAYMENT,
]
const NO_LOADER_API = []
const OPEN_API = [LOGIN, GET_SOCIAL_AUTH, REPORT_ERROR, GET_COUNTRY_LIST, REGISTER_USER, RECOVER_PASSWORD, CONTACT_SUPPORT, GET_OTP, RESEND_VERIFICATION_LINK] // No Token Attached.
function wait(delay) {
	return new Promise(resolve => setTimeout(resolve, delay))
}
function apiCallWithRetry(url, delay, tries, fetchOptions = {}, otherOptions) {
	function onError(error) {
		let triesLeft = tries - 1
		if (!triesLeft) {
			throw error
		}
		return wait(delay).then(() => apiCallWithRetry(url, delay, triesLeft, fetchOptions, otherOptions))
	}
	return fetch(url, fetchOptions)
		.then(response => {
			if (otherOptions.URL_KEY === 'UPLOAD_MULTIPART_FILE') {
				let responseObject = {
					status_code: response.status,
					data: {
						etag: JSON.parse(response.headers.get('ETag')),
						partNumber: otherOptions.partNo,
					},
				}
				return responseObject
			}
			return response.json()
		})
		.catch(error => {
			if (error.message.includes('The user aborted a request.')) {
				throw error
			}
			console.log('error on partno ' + otherOptions.partNo, ' ', error.message)
			return onError(error)
		})
}
function successHandler(response) {
	if (response.status_code === 500) {
		return Promise.reject(response.status_message)
	} else if (response.status === 500) {
		return Promise.reject(response.message)
	} else if (response.status === 401) {
		bindActionCreators(logOut, store.dispatch)({ auto: true })
		message.error('Session Expired.')
		return Promise.reject(response)
	} else if (response.status_code === 404) {
		if (response.status_message === 'account id not created') {
			return Promise.reject('Data not uploaded yet!')
		} else if (response.status_message === 'Data Preview or Final Data is not uploaded.') {
			// return Promise.resolve('Please upload the data!')
		} else {
			return Promise.resolve(response)
		}
	} else {
		return Promise.resolve(response)
	}
}
function errorHandler(response) {
	return Promise.reject(response)
}
function catchHandler(response) {
	if (typeof response !== 'object') {
		message.error(response)
	} else {
		if (response.status_code === 401 || response.status === 401) {
			bindActionCreators(logOut, store.dispatch)()
			message.error('Session Expired.')
		} else {
			goTo('server-down')
		}
	}
}
function appendPathParams(URL, pathParams) {
	let URL_WITH_PARAMS = URL
	const pathParamsArray = Object.keys(pathParams)
	let pathParamsKey, pathParamsValue
	for (let index = 0; index < pathParamsArray.length; index++) {
		pathParamsKey = pathParamsArray[index]
		pathParamsValue = pathParams[pathParamsKey]
		URL_WITH_PARAMS += index === 0 ? '/' : '/'
		URL_WITH_PARAMS += pathParamsKey + '/' + pathParamsValue
	}
	return URL_WITH_PARAMS
}
function appendQueryParams(URL, queryParams) {
	let URL_WITH_PARAMS = URL
	const queryParamsArray = Object.keys(queryParams)
	let queryParamKey, queryParamValue
	for (let index = 0; index < queryParamsArray.length; index++) {
		queryParamKey = queryParamsArray[index]
		queryParamValue = queryParams[queryParamKey]
		URL_WITH_PARAMS += index === 0 ? '?' : '&'
		URL_WITH_PARAMS += queryParamKey + '=' + queryParamValue
	}
	return URL_WITH_PARAMS
}
function toggleLoader(URL_KEY) {
	if (MODAL_LOADER_API.indexOf(URL_KEY) > -1) {
		bindActionCreators(toggleAppLoading, store.dispatch)('MODAL')
	} else if (NO_LOADER_API.indexOf(URL_KEY) > -1) {
		bindActionCreators(toggleAppLoading, store.dispatch)('NO_LOADER')
	} else {
		bindActionCreators(toggleAppLoading, store.dispatch)('PAGE')
	}
}
function apiCall(METHOD, URL_KEY, body, queryParams, abortSignal, retry) {
	// StartLoader
	if (
		URL_KEY !== 'UPLOAD_MULTIPART_FILE' &&
		URL_KEY !== 'GET_MULTIPART_URLS' &&
		URL_KEY !== 'COMPLETE_MULTIPART_UPLOAD' &&
		URL_KEY !== 'ABORT_MULTIPART_UPLOAD' &&
		URL_KEY !== 'UPLOAD_CONTRIBUTION' &&
		URL_KEY !== 'CREATE_CONTRIBUTION'
	) {
		toggleLoader(URL_KEY)
	}

	let endPoint
	// if (URL_KEY === 'UPLOAD_TO_URL') {
	//  endPoint = queryParams === 'PREVIEW' ? body.uploadUrl.previewUrl : body.uploadUrl.uploadUrl
	// }
	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		endPoint = body.url
	} else {
		endPoint = AEROLOGIX_ENDPOINTS[URL_KEY]
	}
	let partNo = null
	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		partNo = body.partNo
	}
	let URL
	// if (URL_KEY === 'UPLOAD_TO_URL') {
	//  URL = endPoint
	//  isUploadUrl = true
	// }

	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		URL = endPoint
		isUploadUrl = true
	} else {
		URL = process.env.REACT_APP_BASE_URL + endPoint
		isUploadUrl = false
	}
	let clonnedBody
	// if (URL_KEY === 'UPLOAD_TO_URL') {
	//  clonnedBody = body.body
	// }
	if (URL_KEY === 'UPLOAD_MULTIPART_FILE') {
		clonnedBody = body.body
	} else if (URL_KEY === 'UPLOAD_FLIGHT_LOG_FILE' || URL_KEY === 'UPLOAD_CONTROL_POINTS_FILE') {
		clonnedBody = body
	} else {
		if (Array.isArray(body)) {
			clonnedBody = Object.assign([], body)
		} else {
			clonnedBody = Object.assign({}, body)
		}
	}
	let requestObj = {
		method: METHOD,
		redirect: 'follow',
		headers:
			// URL_KEY === 'UPLOAD_TO_URL'
			//  ? {
			//          'Content-Type': 'application/zip',
			//    }
			//  :
			{
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
			},
	}
	if (OPEN_API.indexOf(URL_KEY) === -1) {
		// if (URL_KEY !== 'UPLOAD_TO_URL') {
		if (URL_KEY !== 'UPLOAD_MULTIPART_FILE') {
			//  && URL_KEY !== 'GET_MULTIPART_URLS' && URL_KEY !== 'COMPLETE_MULTIPART_UPLOAD' && URL_KEY !== 'ABORT_MULTIPART_UPLOAD'  ) {
			if (URL_KEY === 'RECOVER_PILOT_ACCOUNT') {
				requestObj.headers['Authorization'] = 'Bearer ' + getValueFromStorage('TEMP_TOKEN')
			} else {
				requestObj.headers['Authorization'] = 'Bearer ' + getValueFromStorage('TOKEN')
			}
		}
	}
	requestObj.headers['correlationId'] = uuid()
	requestObj.headers['platform'] = 'DASHBOARD'
	requestObj.headers['appName'] = 'AEROLOGIXMAPS'
	if (URL_KEY === 'UPLOAD_FLIGHT_LOG_FILE' || URL_KEY === 'UPLOAD_CONTROL_POINTS_FILE') {
		requestObj.headers = new Headers()
		requestObj.headers.append('Authorization', 'Bearer ' + getValueFromStorage('TOKEN'))
		requestObj.headers.append('correlationId', uuid())
		requestObj.headers.append('platform', 'DASHBOARD')
	}

	if (body || queryParams) {
		if (METHOD === 'GET' || METHOD === 'DELETE') {
			if (body) {
				// pathparams in this case
				URL = appendPathParams(URL, body)
				// if query params is also there
				if (queryParams) {
					URL = appendQueryParams(URL, queryParams)
				}
			} else if (queryParams) {
				URL = appendQueryParams(URL, queryParams)
			}
		} else if (METHOD === 'POST' || METHOD === 'PUT') {
			if (URL_KEY === 'UPLOAD_FLIGHT_LOG_FILE' || URL_KEY === 'UPLOAD_CONTROL_POINTS_FILE') {
				requestObj.body = clonnedBody
			} else {
				requestObj.body = JSON.stringify(clonnedBody)
			}
		}
	}
	if (abortSignal) {
		requestObj['signal'] = abortSignal
	}

	return (
		fetch(URL, requestObj)
			.then(res => {
				if (
					URL_KEY !== 'UPLOAD_MULTIPART_FILE' &&
					URL_KEY !== 'GET_MULTIPART_URLS' &&
					URL_KEY !== 'COMPLETE_MULTIPART_UPLOAD' &&
					URL_KEY !== 'ABORT_MULTIPART_UPLOAD' &&
					URL_KEY !== 'UPLOAD_CONTRIBUTION' &&
					URL_KEY !== 'CREATE_CONTRIBUTION'
				) {
					// Stop Loader.
					toggleLoader(URL_KEY)
				}
				if (isUploadUrl) {
					let response = {
						status_code: res.status,
						//data: true,
						data: {
							// ETag: JSON.parse(res.headers.get('ETag')),
							// PartNumber: partNo
							etag: JSON.parse(res.headers.get('ETag')),
							partNumber: partNo,
						},
					}
					return response
				} else {
					return res.json()
				}
			})
			.then(successHandler, errorHandler)
			//.catch(catchHandler)
			.catch(error => {
				console.log(error)
				if (error.message && error.message === 'The user aborted a request.') {
					throw error
				}
				if (retry) {
					return apiCallWithRetry(URL, 10000, 15, requestObj, { partNo, URL_KEY })
				}
				// if not aborted or retried
				if (
					URL_KEY === 'UPLOAD_MULTIPART_FILE' ||
					URL_KEY === 'GET_MULTIPART_URLS' ||
					URL_KEY === 'COMPLETE_MULTIPART_UPLOAD' ||
					URL_KEY === 'ABORT_MULTIPART_UPLOAD' ||
					URL_KEY !== 'UPLOAD_CONTRIBUTION' ||
					URL_KEY !== 'CREATE_CONTRIBUTION'
				) {
					// Stop Loader.
					throw error
				}
				catchHandler(error)
			})
	)
}
export function get(URL_KEY, pathParams, queryParams) {
	return apiCall('GET', URL_KEY, pathParams, queryParams)
}
export function deleteMethod(URL_KEY, pathParams, queryParams, abortSignal = null, retry = null) {
	return apiCall('DELETE', URL_KEY, pathParams, queryParams, abortSignal, retry)
}
export function post(URL_KEY, body, abortSignal = null, retry = null) {
	return apiCall('POST', URL_KEY, body, null, abortSignal, retry)
}
export function put(URL_KEY, body, type, abortSignal = null, retry = null) {
	return apiCall('PUT', URL_KEY, body, type, abortSignal, retry)
}
